const config = {
  apiGateway: {
    REGION: 'eu-west-1',
    PHY_LOCATION_APIGATEWAY_URL: 'https://cvtn2zsbhd.execute-api.eu-west-1.amazonaws.com/prod',
    INTERNAL_PHY_LOCATION_APIGATEWAY_URL: 'https://elb7t397j2.execute-api.eu-west-1.amazonaws.com/prod',
    COMPANY_APIGATEWAY_URL: 'https://1ax8zbf5kh.execute-api.eu-west-1.amazonaws.com/prod',
  },
  reactApp: {
    VERSION: 'taxonomy-physical-location-1.12.3',
    HOSTNAME: 'https://locations.europe.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.europe.forwoodsafety.com',
    WEBSOCKET: 'wss://a1l7hepeqj.execute-api.eu-west-1.amazonaws.com/prod'
  },
};

export default config;